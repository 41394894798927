/**
 * 
 * Controlamos las etiquetas meta de la página
 * 
 * Cualquier duda preguntar a Asier
 * 
 */
function setMetaTag(attr, content, attrType = 'name') {
  let query = attrType === 'http-equiv' ? `meta[http-equiv="${attr}"]` : `meta[${attrType}="${attr}"]`;
  let element = document.head.querySelector(query);
  if (!element) {
      element = document.createElement('meta');
      if (attrType === 'http-equiv') {
          element.setAttribute('http-equiv', attr);
      } else {
          element.setAttribute(attrType, attr);
      }
      document.head.appendChild(element);
  }
  element.setAttribute('content', content);
}


function setLinkTag(rel, href) {
  let element = document.head.querySelector(`link[rel="${rel}"]`);
  if (!element) {
      element = document.createElement('link');
      element.setAttribute('rel', rel);
      document.head.appendChild(element);
  }
  element.setAttribute('href', href);
}

export default function updateMetaTags(meta) {
  Object.keys(meta).forEach(key => {
      if (key === 'title') {
          document.title = meta[key]; // Actualiza el título de la página.
      } else if (key.startsWith('og:') || key.startsWith('twitter:')) {
          setMetaTag(key, meta[key], 'property');
      } else if (key === 'canonicalUrl') {
          setLinkTag('canonical', meta[key]);
      } else if (key.includes('schema.')) {
          setLinkTag(key.replace('schema.', ''), meta[key]);
      } else if (key === 'icon') {
          setLinkTag('icon', meta[key]);
      } else {
          setMetaTag(key, meta[key], key.includes('http-equiv') ? 'http-equiv' : 'name');
      }
  });
}