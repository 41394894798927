export default () => ({
  usuarioAutorizado: false,
  idDemo: false,
  idUsuario: false,
  idGrupo: false,
  idDelegacion: false,
  crosschex_id: false,
  nombreUsuario: '',
  primerApellidoUsuario: false,
  segundoApellidoUsuario: false,
  emailUsuario: false,
  passwordUsuario: false,
  movilUsuario: false,
  latitud: null,
  longitud: null,
  geolocalizacion: false,
  rol: 0,
  fichaje: 0,
  colorUsuario: '#c7052e',
  fechaAlta: new Date(),
  imagen: null,
  // Permisos
  permiso_modulo_erp:0,
  permiso_modulo_contabilidad: 0,
  permiso_modulo_marketing: 0,
  permiso_modulo_rrhh: 0,
  permiso_modulo_crm: 0,
  permiso_modulo_cms: 0,
  permiso_modulo_web: 0,
  permiso_modulo_taller: 0,
  permiso_modulo_sga: 0,
  //permiso_modulo_bi: 0,
  permiso_abono_clientes: 0,
  permiso_categorias: 0,
  permiso_clientes: 0,
  permiso_exportar_informes: 0,
  permiso_modificar_lineas_facturas: 0,
});
