export default () => ({
    idClienteGeneral:null,
    nombreClienteGeneral: '',
    identificadorLargoClienteGeneral: null,
    emailClienteGeneral: '',
    passwordClienteGeneral: '',
    telefonoClienteGeneral: '',
    tipoClienteGeneral: '',
    fechaAltaClienteGeneral: '',
  });
  