<template>
    <web-layout>
      <div class="container" style="margin-top:100px">
        <div class="alert alert-success">
          <b>Página web en construcción</b>
          Puede ponerse en contacto con nosotros en
          <a href="mailto:info@vpro360.es">info@vpro360.es</a>
        </div>
      </div>
      <div>
        <div class="container">
          <h1 class="display-5 fw-bold text-body-tertiary">
            Software <b>SGA</b> Cloud
          </h1>
        </div>
      </div>
    </web-layout>
  </template>
  
  <script>
  import { defineAsyncComponent } from 'vue';
  export default {
    components: {
      WebLayout: defineAsyncComponent(() => import('@/web/WebLayout.vue')),
    },
  };
  </script>
  