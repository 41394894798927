import { createStore } from 'vuex';
import tecdocStore from '@/modulos/erp/store/tecdoc';
import callCenterStore from '@/modulos/erp/store/callcenter';
import usuariosStore from '@/modulos/rrhh/store/usuarios';
import clientesStore from '@/store/clientes';

export default createStore({
  state: {
    // Configuración Global
    token: null,
    token_usuario: null,
    nombre_empresa: null,
    nombre_empresa_corto: null,
    modelo_vacaciones_empresa: null,
    vacaciones_dias_naturales: null,
    aparato_fichaje: null,
    crosschex_id: 0,
    // Módulos
    modulo_erp: 0,
    modulo_rrhh: 0,
    modulo_soporte: 0,
    modulo_contabilidad: 0,
    modulo_marketing: 0,
    modulo_configuracion: 0,
    modulo_documentacion: 0,
    modulo_logs: 0,
    modulo_personalizacion: 0,
    modulo_sga: 0,
    modulo_crm: 0,
    modulo_cms: 0,
    modulo_web: 0,
    modulo_taller: 0,
    // Submódulos
    submodulo_fabricantes: 0,
    // Submódulos vacaciones
    submodulo_vacaciones_solicitar: 0,
    submodulo_vacaciones_pendientes_aprobar: 0,
    submodulo_vacaciones_dias_disponibles: 0,
    submodulo_vacaciones_normativa: 0,
    // Estilos
    id_configuracion_global: null,
    navbar_background_color: null,
    navbar_text_color: '#ffffff',
    body_background_color: null,
    body_text_color: null,
    // Botones
    btnInicio: true, // Por defecto visible
    btnConsultas: false,
    btContactos: false,
    btnDocumentos: false,
    btnProductos: false,
    btnReferencias: false,
    btnVentas: false,
    btnCalendario: false,
    btnTareas: false,
    btnPedidos: false,
    btnCaja: false,
    btnBanco: false,
    btnRRHHInicio: false,
    btnRRHHGrupos: false,
    btnRRHHUsuarios: false,
    btnRRHHEmpleados: false,
    btnRRHHFichajes: false,
    btnRRHHVacaciones: false,
    btnCONFIGURACIONInicio: false,
    btnCONFIGURACIONEmpresas: false,
    btnCONFIGURACIONFabricantes: false,
    btnCONFIGURACIONAgentes: false,
    btnCONFIGURACIONGruposComerciales: false,
    btnCONFIGURACIONImpuestos: false,
    btnCONTABILIDADLibro: false,
    btnCONTABILIDADCuentasContables: false,
    btnCONTABILIDADAsientosContables: false,
    btnSoporteClientes: false,
    btnSoporteProductos: false,
    btnSoporteDemos: false,
    // Taller
    horasTrabajoDisponibles: 0,
  },
  modules: {
    tecdoc: tecdocStore,
    callCenter: callCenterStore,
    usuarios: usuariosStore,
    clientes: clientesStore,
  },
});
