<template>
  <web-layout>
    <div class="container" style="margin-top: 100px">
      <div class="alert alert-success">
        <b>Página web en construcción</b>
        Puede ponerse en contacto con nosotros en
        <a href="mailto:info@vpro360.es">info@vpro360.es</a>
      </div>
    </div>

    <contenedor-principal />

    <ventajas />

    <precios />
  </web-layout>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    WebLayout: defineAsyncComponent(() => import('@/web/WebLayout.vue')),
    ContenedorPrincipal: defineAsyncComponent(() =>
      import('@/web/components/ContenedorPrincipal.vue'),
    ),
    Ventajas: defineAsyncComponent(() =>
      import('@/web/components/Ventajas.vue'),
    ),
    Precios: defineAsyncComponent(() => import('@/web/components/Precios.vue')),
  },
};
</script>
