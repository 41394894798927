let BASE_URL='';

if (process.env.NODE_ENV === 'development') {
    // URL base para el entorno de desarrollo
    BASE_URL = 'http://localhost:8000';
  } else {
    // URL base para el entorno de producción
    BASE_URL = 'https://vpro360.es';
  }
  
  export default BASE_URL;