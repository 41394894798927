import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router/router';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

import CKEditor from '@ckeditor/ckeditor5-vue';

import './styles/styles.scss';
import '../public/main.css';


createApp(App)
  .use(store)
  .use(router)
  .use(VueSweetalert2)
  .use(VueVideoPlayer)
  .use(CKEditor)
  .mount('#app');
