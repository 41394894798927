export function swalError(message, swalElement, swalEmit, close) {
  swalElement({
    icon: 'error',
    title: '¡Error!',
    text: message,
    showConfirmButton: false,
    timer: 1500,
  });
  if (close) {
    swalEmit('cerrar-modal');
  }
}
export function swalSuccess(message, swalElement, swalEmit, close) {
  swalElement({
    icon: 'success',
    title: '¡Genial!',
    text: message,
    showConfirmButton: false,
    timer: 1500,
  });
  if (close) {
    swalEmit('cerrar-modal');
  }
}
export function swalAsk(questionText, confirmText, swalElement) {
  swalElement
    .fire({
      title: '¿Estás seguro?',
      text: questionText,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmText,
      cancelButtonText: 'Cancelar',
    })
    .then((result) => {
      console.log(result.isConfirmed);
      return result.isConfirmed;
    });
}
export function swalAlert(message, swalElement, swalEmit, close) {
  swalElement.fire({
    title: 'Advertencia',
    icon: 'info',
    text: message,
    showCloseButton: true,
    focusConfirm: false,
  });
  if (close) {
    swalEmit('cerrar-modal');
  }
}
